import { authGuard } from '@auth0/auth0-vue';
import { RouteComponent, RouteRecordRaw } from 'vue-router';

import { RouteName } from '@/routes/routeNames';

export type MediahubRoute = RouteRecordRaw & {
	name: RouteName;
};
const routes: MediahubRoute[] = [
	{
		path: '/',
		component: (): Promise<RouteComponent> => import('@/pages/home/Home.vue'),
		name: RouteName.Root,
		beforeEnter: authGuard,
	},
	{
		path: '/about',
		component: (): Promise<RouteComponent> => import('@/pages/about/About.vue'),
		name: RouteName.About,
		beforeEnter: authGuard,
	},
	{
		path: '/ratings',
		component: (): Promise<RouteComponent> =>
			import('@/pages/ratings/Ratings.vue'),
		name: RouteName.Ratings,
		beforeEnter: authGuard,
	},
];

export default routes;
