import Log from '@invidi/common-edge-logger-ui';
import {
	createRouter as createVueRouter,
	createWebHistory,
	RouteComponent,
	Router,
} from 'vue-router';

import { RouteName } from '@/routes/routeNames';
import routes from '@/routes/routes';

const topLogLocation = 'src/router.ts';

export type CreateRouterOptions = {
	log: Log;
};

export let router: Router;

export function createRouter(options: CreateRouterOptions): Router {
	const { log } = options;
	const logLocation = `${topLogLocation}: createRouter()`;

	log.notice('Creating Vue Router', { logLocation });

	const newRouter = createVueRouter({
		history: createWebHistory(String(import.meta.env.BASE_URL)),
		routes: [
			...routes,
			// Final catch-all 404-view
			{
				component: (): Promise<RouteComponent> =>
					import('@/pages/errors/NotFound.vue'),
				name: RouteName.NotFound,
				path: '/:pathMatch(.*)*',
			},
		],
	});

	router = newRouter;

	return newRouter;
}

export default createRouter;
