<template>
	<UIUtilityMenu
		menuId="logout-menu"
		:placement="UIMenuPlacement.LeftTop"
		class="user-nav-item"
	>
		<template #trigger>
			<span class="avatar text" data-testid="avatar-text" :title="user.name">
				{{ userInitials }}
			</span>
		</template>
		<template #body>
			<ul>
				<li>
					<button data-testid="logout-button" @click="logOut">Logout</button>
				</li>
			</ul>
		</template>
	</UIUtilityMenu>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import {
	UIMenuPlacement,
	UIUtilityMenu,
} from '@invidi/conexus-component-library-vue';
import { computed } from 'vue';

import { config } from '@/globals/config';

const { user, logout } = useAuth0();

const userInitials = computed(() =>
	user.value.name
		? user.value.name
				.split(' ')
				.slice(0, 2)
				.map((namePart) => namePart.charAt(0).toUpperCase())
				.join('')
		: null
);

const logOut = (): void => {
	logout({
		logoutParams: { returnTo: config.auth0Config.redirectUri },
	});
};
</script>
