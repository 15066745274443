import Log, { LogSeverity } from '@invidi/common-edge-logger-ui';

// Create default logger
export let log = new Log({
	colors: false,
	minLogLevel: 'NOTICE' as LogSeverity,
	outputType: 'string',
});

export function setLogger(newLogger: Log): void {
	log = newLogger;
}

export default log;
